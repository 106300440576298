export default [
  {
    path: "/class-rooms/show-course/:id",
    name: "ShowCourse",
    component: () => import("@/views/classrooms/showCourses/ShowCourses.vue"),
    meta: {
      pageTitle: "g.show_course",
      navActiveLink: "Profile",
      breadcrumb: [
        {
          text: "g.show_course",
          active: true,
        },
      ],
    },
  },
  {
    path: "/class-rooms/show-course/show-board/:id",
    name: "ShowBoard",
    component: () =>
      import("@/views/classrooms/boards/showBoard/ShowBoard.vue"),
    meta: {
      pageTitle: "g.show_board",
      navActiveLink: "Profile",

      breadcrumb: [
        {
          text: "g.show_board",
          active: true,
        },
      ],
    },
  },
  {
    path: "/class-rooms/course-details/:id",
    name: "CourseDetails",
    component: () =>
      import("@/views/classrooms/courseDetails/CourseDetails.vue"),
    meta: {
      pageTitle: "g.CourseDetails",
      navActiveLink: "Profile",

      breadcrumb: [
        {
          text: "g.CourseDetails",
          active: true,
        },
      ],
    },
  },
  {
    path: "/class-rooms/boards/:id",
    name: "Boards",
    component: () =>
      import("@/views/classrooms/boards/showBoard/ShowBoard.vue"),
    meta: {
      pageTitle: "g.showBoard",
      navActiveLink: "Profile",
      breadcrumb: [
        {
          text: "g.showBoard",
          active: true,
        },
      ],
    },
  },
  {
    path: "/class-rooms/boards-list/:id",
    name: "BoardsList",
    component: () =>
      import("@/views/classrooms/boards/boardList/boardList.vue"),
    meta: {
      pageTitle: "g.boardList",
      navActiveLink: "Profile",
      breadcrumb: [
        {
          text: "g.boardList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/class-rooms/boards/:boardId/list/:listId/posts/:postId",
    name: "showPost",
    component: () =>
      import("@/components/classrooms/boards/posts/showPost/index.vue"),
    meta: {
      pageTitle: "g.showPost",
      navActiveLink: "Profile",

      breadcrumb: [
        {
          text: "g.showPost",
          active: true,
        },
      ],
    },
  },
  {
    path: "/class-rooms/show-course/:id/students",
    name: "CourseStudents",
    component: () =>
      import("@/views/classrooms/courseStudents/courseStudents.vue"),
  },
  {
    path: "/class-rooms/academic-courses/:id",
    name: "academicCourses",
    component: () => import("@/views/classrooms/academicCourses/index.vue"),
    meta: {
      pageTitle: "g.academicCourses",
    },
  },
  {
    path: "/class-rooms/package/course/:id",
    name: "ShowPackageCourse",
    component: () =>
      import("@/views/classrooms/academicCourses/showExplore/index.vue"),
  },
  {
    path: "/class-rooms/package/:id",
    name: "ShowPackage",
    component: () =>
      import("@/views/classrooms/academicCourses/packages/show/index.vue"),
  },
];
