export default {
  namespaced: true,
  state: {
    list: [],
  },
  actions: {},
  mutations: {
    AddBreadcrumb(state, value) {
      state.list.push(value);
    },
    Set_BreadCrumb(state, payload) {
      const { index } = payload;
      state.list[index].text = payload.value.text;
    },
  },
  // actions: {},
};
