export default [
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/views/personal/profile/profile.vue"),
    meta: {
      pageTitle: "Home",
      navActiveLink: "Profile",
    },
  },
  {
    path: "/edit-profile",
    name: "EditProfile",
    component: () => import("@/views/personal/Edit-profile/editProfile.vue"),
    meta: {
      navActiveLink: "Profile",
      breadcrumb: [
        {
          active: true,
        },
      ],
    },
  },
  {
    path: "/show-student/:id",
    name: "showStudent",
    component: () => import("@/views/personal/showStudent/showStudent.vue"),
  },
  {
    path: "/show-teacher/:id",
    name: "showTeacher",
    component: () => import("@/views/personal/showTeacher/showTeacher.vue"),
  },
  {
    path: "/show-parent/:id",
    name: "ShowParent",
    component: () => import("@/views/personal/showParent/showParent.vue"),
  },
];
