export default [
  {
    path: "/cart",
    name: "Cart",
    component: () => import("@/views/cart/index.vue"),
  },
  {
    path: "/checkout",
    name: "OrderDone",
    component: () => import("@/views/cart/checkoutSuccess/index.vue"),
  },
];
