import axiosIns from "@axios";

export default {
  state: {
    currencies: [],
  },
  getters: {
    GET_CURRENCIES(state){
      return state.currencies
    }
  },
  mutations: {},
  actions: {
    FETCH_DATA_CURRENCIES({ state }) {
      axiosIns
        .get("web/get-active-currency")
        .then((res) => {
          if (res.status === 200) {
            const { data } = res.data;
         state.currencies =   data.map(
                ({ id, currency_code, currency_id, active }) => ({
                  value: id,
                  text: currency_code,
                  currency_id,
                  active,
                })
            );
            }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
